import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  #root {
    height: 100vh;
    display: flex;
    flex: auto;
    flex-direction: column;
  }

  ::selection {
    color: #ffffff;
    background: ${({ theme }) => theme.color.primary};
  }

  html,
  body {
    scroll-behavior: smooth;
    width: 100%;
    height: 100%;
    overflow-x: auto;
  }

  body {
    margin: 0;
    font-family: monospace;
    line-height: 1.4;
    background: #0a0a0a;
    background-size: 5vw 5vw;
    background-image: ${({
      theme,
    }) => `linear-gradient(to right, ${theme.color.primary}14 1px, transparent 1px),
      linear-gradient(to bottom,  ${theme.color.primary}14 1px, transparent 1px)`};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: ${({ theme }) => theme.color.primary};
    text-decoration: none;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
  }

  p {
    margin: 0;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    text-indent: 0;
  }

  td {
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.primary};
    border: 0px none ${({ theme }) => theme.color.primary};
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.color.primary};
  }
  ::-webkit-scrollbar-thumb:active {
    background: ${({ theme }) => theme.color.primary};
  }
  ::-webkit-scrollbar-track {
    background: #0a0a0a;
    border: 0px none ${({ theme }) => theme.color.primary};
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #0a0a0a;
  }
  ::-webkit-scrollbar-track:active {
    background: #0a0a0a;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export default GlobalStyle;
