import About from 'About';
import GlobalStyle from 'css/GlobalStyle';
import Header from 'Header';
import React from 'react';
import { TwitterPicker } from 'react-color';
import createGlobal from 'react-use-global';
import styled, { ThemeProvider } from 'styled-components';
import { ReactComponent as LogoSVG } from 'assets/logo.svg';

const Body = styled.main`
  height: 100%;
  width: 70%;
  margin: auto;
  padding-top: 12.5vw;
  div + div {
    margin-top: 30px;
  }
`;

export const useGlobal = createGlobal({
  color: '#ffffff',
});

const Logo = styled(LogoSVG)`
  width: 10vw;
  height: 10vw;
`;

function App() {
  const [color, setColor] = useGlobal('color');

  return (
    <ThemeProvider
      theme={{
        color: {
          primary: color,
        },
      }}>
      <GlobalStyle />
      <Header />
      <Body>
        <Logo fill={color} />
        <About />
        <TwitterPicker
          triangle='hide'
          color={color}
          onChangeComplete={(color) => setColor(color.hex)}
        />
      </Body>
    </ThemeProvider>
  );
}

export default App;
