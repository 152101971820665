import React, { Suspense } from 'react';
import styled from 'styled-components';
import { ReactComponent as github } from 'assets/github.svg';
import { ReactComponent as linkedin } from 'assets/linkedin.svg';
import Logo from 'logo/Logo';
import { Canvas } from 'react-three-fiber';
import { useGlobal } from 'App';

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  width: 70vw;
  padding: 1vw 0;
  margin: 0 15vw;
  position: fixed;
  top: 0;
  transition: 0.2s;
  vertical-align: center;
  grid-template-columns: 5vw 15vw auto 5vw /*5vw 5vw*/ 5vw;
  user-select: none;
`;

const Name = styled.span`
  font-size: 2vw;
  text-align: center;
  font-family: monospace;
  color: ${({ theme }) => theme.color.primary};
`;

const Button = styled.span`
  font-size: 1vw;
  text-align: center;
  font-family: monospace;
  color: ${({ theme }) => theme.color.primary};

  cursor: pointer;
  transition: opacity 150ms linear;
  &:hover {
    opacity: 0.7;
  }
`;

const Icons = styled.div`
  text-align: center;
  svg + svg {
    margin-left: 0.5vw;
  }
`;

const Github = styled(github)`
  height: 2vw;
  width: 1.5vw;
  cursor: pointer;
  transition: opacity 150ms linear;
  &:hover {
    opacity: 0.7;
  }
`;

const Linkedin = styled(linkedin)`
  height: 2vw;
  width: 1.5vw;
  cursor: pointer;
  transition: opacity 150ms linear;
  &:hover {
    opacity: 0.7;
  }
`;

const LogoWrapper = styled.div`
  width: 5vw;
  height: 2.5vw;
`;

const Header = () => {
  const [color] = useGlobal('color');

  return (
    <Wrapper id='header'>
      <LogoWrapper>
        <Canvas>
          <ambientLight color='darkgray' />
          <pointLight position={[10, 10, 20]} />
          <Suspense fallback={null}>
            <Logo />
          </Suspense>
        </Canvas>
      </LogoWrapper>
      <Name>Ivan Zvonar</Name>
      <div />
      {/*<Button>Work</Button>
      <Button>Skills</Button> */}
      <Button>
        <a href='mailto:ivan@zvonar.dev'>Contact</a>
      </Button>
      <Icons>
        <a href='https://github.com/nevecex'>
          <Github fill={color} alt='Github' />
        </a>
        <a href='https://www.linkedin.com/in/ivanzvonar/'>
          <Linkedin fill={color} alt='Linkedin' />
        </a>
      </Icons>
    </Wrapper>
  );
};

export default Header;
