import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from 'react-three-fiber';
import { DoubleSide } from 'three';
import { useGlobal } from 'App';

const Logo = (props) => {
  const group = useRef();
  const { nodes } = useGLTF('logo.gltf');
  const mesh = useRef();
  useFrame(() => {
    mesh.current.rotation.y += 0.005;
  });
  const [color] = useGlobal('color');

  return (
    <group scale={[0.4, 0.4, 0.4]} ref={group} {...props} dispose={null}>
      <mesh ref={mesh} geometry={nodes.Capsule.geometry}>
        <meshStandardMaterial color='white' />
      </mesh>
      <mesh geometry={nodes.Extrude5.geometry}>
        <meshStandardMaterial color={color} side={DoubleSide} />
      </mesh>
    </group>
  );
};

useGLTF.preload('logo.gltf');

export default Logo;
