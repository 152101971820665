import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  border-left: solid 3px ${({ theme }) => theme.color.primary};
  padding-left: 10px;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.color.primary};
  font-size: 30px;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.color.primary};
  font-size: 20px;
`;

const About = () => {
  return (
    <Wrapper>
      <Title>Full Stack Developer</Title>
      <Text>Android ( Java & Kotlin ), React.JS</Text>
      <Text>Node.JS</Text>
      <Title>2D and 3D Graphics Editor</Title>
      <Text>Photoshop, Maxon Cinema 4D...</Text>
    </Wrapper>
  );
};

export default About;
